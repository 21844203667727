
.home{
	// .container{		
		// .container(fluid);
		
		& .full{
			.row(9);
			float: right;
		}
		
		& .flux{
			& h2{padding: 20px 0;}
			.col(8);
			.push(1);
			
			@media @tablet, @mobile {.push(0);};

			.col(12,@tablet);
			.col(12,@mobile);
		}

		& .sidebar{
			margin-top: 100px;
			.col(4);
			.pull(1);	
			
			@media @tablet, @mobile {
			.col(12);
			.pull(0);	
			};		
		}
	& .row{
		padding: 0 @simple-padding;
		display: block;
		min-height: 1952px;
		& .soft-sidebar {
			.col(8);
            @media @mobile {
              .col(12);
				padding: 0;
            }
			& h1 {
				color: @brand-second;
				.font(28);
				padding: @simple-padding 0;
				font-weight: 300;
			}
			& .page_block {
				width: 100%;
				border-radius: 5px;
				float:left;
				margin-bottom: 1rem;
				position: relative;
				height: 0;
				padding: @simple-padding;
				padding-bottom: 50%; /* for a 4:3 aspect ratio */
				background-position: center center;
				background-size: 100%;
				background-repeat: no-repeat;

				& .infos {
					position: absolute;
					bottom: 1rem;
					right: 2rem;
					width: 72%;
					height: 40%;
                    @media @mobile {
                    	right: 1.2rem;
						bottom: 0rem;
                    }
					& h1 {
						.font(32);
						@media @desktop {
							.font(28);
						}
						@media @tablet {
							.font(22);
                            line-height: 18px;
                            line-height: 1.8rem;
						}
						@media @mobile {
							.font(16);
						}
						width: 60%;
						color: @brand-second;
                        padding: 0;
						font-weight: 300;
					}
					& h2 {
						.font(18);
						@media @desktop {
							.font(16);
						}
						@media @tablet {
							.font(14);
						}
                        @media @mobile {
                            .font(12);
							line-height: 17px;
							line-height: 1.7rem;
                        }
						.mq-max(450px,{
							display: none;
						});

						color: @font-darker-color;
						text-transform: none;
						width: 60%;
						font-weight: 100;
					}
					& .bottom-buttton {
						width: 40%;
						position: absolute;
						right: 0;
						bottom: 0;
						& .button {
							color: #f1f1f1;
							font-size: 15px;
							padding-right: 4rem;
							font-family: ArialNarrow;
							@media @tablet {
								padding-right: 2rem;
								min-width: 131px;
								.font(13);
							}
							@media @mobile {
								padding-right: 3rem;
								.font(13);
							}
							@media @desktop {
								.font(15);
							}
							line-height: 40px !important;
							line-height: 4rem !important;
							.border-radius(@border-radius-light);
							text-align: left;
							width: initial;
							float: right;
							font-weight: normal;
							&:after {
								content: "\e92b";
								font-family: hollyglyph !important;
								position: absolute;
								right: 1rem;
								@media @tablet, @desktop {
									right: 0.8rem;
								}
							}
						}
					}
				}
			}
		}

		& .widget-sidebar {
			.push(0.5);
			.col(3.5);
          @media @mobile {
			  .push(0);
			  .col(6);
          }
			float: left;
			& ul.link-list {
				list-style: none;
				& p {
					.font(16);
				}
				& li {
					line-height: 20px;
					line-height: 2rem;
					margin-bottom: 2rem;
					&:before{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 3.5px 0 3.5px 7px;
						border-color: transparent transparent transparent @brand-second;
						margin-right: 1.2rem;
						display: inline-block;
						position: relative;
						bottom: 0.6rem;
					}
					& a {
						display: inline-block;
						font-size: 18px;
						font-size: 1.8rem;
						line-height: 20px;
						line-height: 2rem;
						height: 100%;
						overflow: hidden;
					}
				}
			}
			& .stories-list {
				list-style: none;
				& li{
					border-top: 1px solid @soft-border-color;
					border-bottom: 1px solid @soft-border-color;
					border-right:3px solid transparent;
					line-height: 0;
					height: 15rem;
					.font(16);
					&:before {
						.hide();
					}
					& .date {
						width: 48%;
						margin-bottom: 1rem;
						float: left;
						margin-top: 2rem;
						color: @brand-second;
						padding-left: 10px;
					}
					& .title {
						width: 48%;
						float:left;
						color: @font-dark-color;
						padding-left: 10px;
					}
					& .framed {
						width: 50%;
						float: left;
						padding-right: 10px;
						height: 100%;
						background-repeat: no-repeat;
					}
					&:hover {
						border-right:3px solid @brand-second;
						background: #f7f7f7;
					}
				}
			}
			& h3 {
				padding: @simple-padding 0;
				color: @brand-second;
				.font(28);
				font-weight: 300;
				margin: 19px 0;
			}
		}
	}
}
	.content-header{

		& h2{
		   padding: 20px 0;
		   display: none;
		}
	  
	// }
}