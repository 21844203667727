@font-face {
  font-family: Univers;
  src: url("../fonts/UniversLTStd-Cn.eot");
  src:  local("☺"),
  url("../fonts/UniversLTStd-Cn.otf") format('otf'),
  url("../fonts/UniversLTStd-Cn.svg") format("svg"),
  url("../fonts/UniversLTStd-Cn.woff") format("woff"),
  url("../fonts/UniversLTStd-Cn.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Univers;
  src: url("../fonts/UniversLTStd-LightCn.eot");
  src:  local("☺"),
  url("../fonts/UniversLTStd-LightCn.otf") format('otf'),
  url("../fonts/UniversLTStd-LightCn.svg") format("svg"),
  url("../fonts/UniversLTStd-LightCn.woff") format("woff"),
  url("../fonts/UniversLTStd-LightCn.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }