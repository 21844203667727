@body-min-width: 200px;
@body-max-width: 1400px;

body{
	& > .container{
		position: relative;
		min-height: 700px;
		height: 100%;
		max-width: @body-max-width;
		border-left: @input-border-hover;
		border-right: @input-border-hover;
		margin: 0 auto
	}
	&.galleries{
		& >.container{
			min-height: 1107px;
		}
	}
	min-width: @body-min-width;
}
html, body {
	margin: 0;
	padding: 0;
	font-family: @font-family-base;
	font-weight: 100;
	font-size: 62.5%;
	position: relative;
}


hr {
  margin-top:    @double-padding;
  margin-bottom: @double-padding;
  border: 0;
  border-top: 1px solid @separator-color;
}
