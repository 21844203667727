
@auth-text-color: #ffffff;

body.auth {
 margin: 0;
 height: 100%;
 min-height: 100%;
 max-height: initial;
  border-left: 0;
  border-right: 0;
  & .auth-container {
    display: block;
    width: 100%;
    height:100%;
    text-align: center;
    background-color: transparent;
    padding: @triple-padding 0;
    font-family: ArialNarrow;
    @media @mobile{
      padding: 0;
    };
    &:before {
      display:inline-block;
      content: '';
      width:0;
      vertical-align:middle;
    }
    & .auth-centered{
      .container(320);
        @media @mobile{
          .container(280);
        };
      margin: auto;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #c5c1be;

      & input {
        border: 1px solid #c5c1be;
        margin: 10px auto;
        max-width: inherit;
      }
      & .container-fluid {
        padding: @simple-padding;
        padding-bottom: @double-padding;
      }
      & .request-access {
        padding: @simple-padding;
      }
      & .ui-checkbox {
        float: left;
        width: 60%;
        @media @mobile, @tablet {
          width: 55%;
        }
        bottom: 2px;
        & input[type="checkbox"] + label:before {
          margin-right:1rem;
        }
      }
      & h2 {
        color: @brand-second;
      }
      & .form-register {
         padding: 0 @simple-padding;
        & #user_registration{
          & label {
            color: @font-dark-color;
          }
        }
      }
    }
    .form-group {
      border-bottom: 0;
    }
    & label {
      height: 25px;
    }
  }
  
  .login-logo {
    text-align: center;
    margin: 0 auto;
    background: @brand-second;
    & a{
      display: block;
      width: 100%;
      .bg-cover(@white-login-png);
      height: 100px;
      margin: 0 auto;
      background-color: @brand-second;
      @media @tablet, @mobile {
        padding: 0 20px;
      }
    }
    p {
      display: none;
      color: @auth-text-color;
      .font(14);
      margin-bottom: 20px;
    }
 
  }
  .container-fluid{
    button[type="submit"] {
      margin: 15px 0;
      text-align: left;
      &:after {
        content: '';
        background: url(@icon-arrow);
        width: 8px;
        height: 14px;
        display: inline-block;
        float: right;
        top: 1.2rem;
        position: relative;
      }
    }
  }
////////////////////////// LANG SWITCH //////////////////////////////


  .lang-switch{
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      background-color:white;
      padding: 10px 10px 10px 10px;


    & li{
      list-style: none;
      display: block;
      float: left;
      margin: 0;
      text-transform: uppercase;
      
      &:first-child {
      &:after {
      content: "|";
      padding: 0 10px;
      }
    }
      a{
      color:#c2c2c2;
      }
     
      a:hover{ 
     
      color: @brand-second;
      text-decoration: none;
      font-weight: bold;
      }
    }
    
    .active{
      
      & a {
        color:@brand-primary;
        font-weight: bold;
      }
    }
  }
////////////////////////// LANG SWITCH //////////////////////////////

  

    

  h2, h4 {
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    .font(32);
    font-weight: 700;  
  }
    
  .form-group .login {
    label {
      color: @auth-text-color;
     .font(13);
     text-transform: none;
     font-weight: 300;
    }

  }
// Pass recover link in login template
  .pass-recover {
    height: auto;
    margin: 0;
    display: inline-block;
    text-align: right;
    width: 40%;
    @media @mobile, @tablet {
      width: 45%;
    }
    border-left: 1px solid #c5c1be;
    a {
      color: @brand-primary;
      font-size: 12px;
      display: inline-block;
      vertical-align: top;
    }
    
    a:hover{color: @brand-primary;}

  } 


// Registration link in login template
  .oauth-text{
    .container(fluid);
    margin-top: @double-padding;
    margin-bottom: @double-padding;
    position: relative;
    
      & hr:first-child{
      left:0
      }
      
      & hr {
      margin-top: 20px;      
      min-width: 10%;
      height: 2px;
      display: block;
      position: absolute;
      top:0;
      margin: 0;
      }

      & hr:last-child{
      right:0
      }
      & p {
        position: absolute;
        top:0;
        margin-top: -10px; 
        padding-left: @simple-padding;
        padding-right: @simple-padding;
        text-align: center;
        position: relative;
        z-index: 2;
        // background-color: @bg-default-color;
      }

  }



  /////////////////////// SIGIN UP /////////////////

/////OLD

  .registration-access {
    margin: 30px 0 30px 0;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    box-sizing: content-box;
    overflow:auto;
    &::after{ 
    clear: both;
    content: "";
    display: table;}


    a{
      width: 120px;
      float: right;

    }
  }

  footer{
    background-color: transparent;
    margin-bottom:0;
    margin-top: 0;
   
   .contact {
      text-align: left;
      font-size: 12px;
      display: block;
      color: #fff;
      margin: 13px auto auto auto;
      color: #737373;
      text-decoration: none;
    }
  }
}



/////////////////////////// SIGN UP LINK

.request-access{
  
  padding-top: @double-padding;
  background: #f6f6f6;
  text-align: right;
  border-top: 1px solid #c5c1be;
  font-size: 16px;
  font-size: 1.6rem;
  & .btn {
    width: 55%;
    margin-left: 1rem;
    text-align: left;
    &:after {
      content: '';
      background: url(@icon-arrow);
      width: 8px;
      height: 14px;
      display: inline-block;
      float: right;
      top: 1.2rem;
      position: relative;
    }
  }
}

.form-register {
  & .btn {
    text-align: left;
    &:after {
      content: '';
      background: url(@icon-arrow);
      width: 8px;
      height: 14px;
      display: inline-block;
      float: right;
      top: 1.2rem;
      position: relative;
    }
  }
}


  .help-block{
      color: @font-dark-color;
     .font(13);
     text-transform: none;
     font-weight: 300;
  }



#user_registration{
  & label{
  color:#ffffff;
  }
}


