

@toolbar-height: 50px;

/////////////////////BASE MODAL OVERLAY -- LAYOUT  ////////////////////


.overlay {
  .rgba(0, 0, 0, .95);
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scroll: hide;

  height: 100%;
  top: 0;

  .overlay-content {
    height: 100%;
    background-color: transparent;
    border: 0;
    border-radius: none;
    position: absolute;
    display: block;
    top: 0;
  }

  .media-dialog {
    & button#paraclose{
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      z-index: 10000;
      &:after{color: #ffffff;}
    }
    width: 100%;
    height: 100%;
    position: fixed;
    & h3 {
      .font(14);
      color: #ffffff;
      position: absolute;
      z-index: 1200;
      left: 0;
      right: 0;
      height: @toolbar-height;
      line-height: @toolbar-height;
      text-align: center;
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;

    }
  }
}

.media-slider-container {
  padding-bottom: 180px!important;
}

[data-no-slider] {
  & .media-slider-container {
    padding-bottom: 50px!important;
  }

  & .slide-list {
    .hide();
  }

  & .overlay-slide-buttons{
    .hide();
  }
}

.mediaContainer {
  float:left;
  width: 100%;
  height: 100%;
  padding-top: @toolbar-height + @double-padding;
  padding-bottom: @double-padding + @double-padding ;
  padding-left: 50px;
  padding-right: 50px;
  & .preview{
    display: block;
    position: relative;
    width: 100%;
    background-color: transparent;
    display:inline-block;
    height:100%;
    overflow:hidden;
    & [data-viewer="image"]{
      &:before {
        display:inline-block;
        content: '';
        height:100%;
        width:0;
        vertical-align:middle;
      }
    }
    & .preview-wrapper{
      display: block;
      width: 100%;
      height:100%;
      text-align: center;
      background-color: transparent;
      position: relative;
      overflow: scroll;
      overflow-x: hidden;
      right:-17px;


      & img{
        max-width: 100%;
        display: inline-block;
        max-height: 100%;
        vertical-align: middle;
      }
      & .icon-only{
        display: inline-block;
        max-height: 100%;
        vertical-align: middle;
        width: auto;
        & .file-icon{
          display: inline-block;
          vertical-align: middle;
          .file-icon-xlarge();
        }
      }
      & canvas{

        overflow: scroll;
        display:block;
        margin:0 auto;
        margin-bottom:3%;
        &:nth-last-of-type(1){
          margin-bottom:0;
        }
      }
    }
  }
  .portrait{
    & img{
      height: initial;
    }
  }
}

.column-left{
  .rgba(0, 0, 0, .95);
  .transition(background-color, 1s);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.6,0,0.4,1);
  transition: transform 0.5s cubic-bezier(0.6,0,0.4,1);
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
  position: relative;
  float: left;
  left:0;
  vertical-align: top;
  display: block;
  height: 100%;
  padding: 0;
  margin-top: 50px;
  padding-bottom: 180px;
  z-index: 2;
  &[data-toggle-state=hidden] {
    .transition(width, 0.5s);
    width:0;
    & ~ .mediaContainer {
      .transition(width, 0.5s);
      width:100%;
    }
  }
  &[data-toggle-state=visible] {
    .col(3);
    -webkit-transform: translate3d(0,60px,0);
    transform: translate3d(0,60px,0);
    padding: 0 15px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: auto;
    background-color: transparent;
    & ~ .mediaContainer {
      width:75%;
      & .context-title{
        -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.6,0,0.4,1);
        transition: transform 0.5s cubic-bezier(0.6,0,0.4,1);
        -webkit-transform: translate3d(0, -66rem, 0);
        transform: translate3d(0, -66rem, 0);
        left:3rem;
      }
    }
    & ~ .column[data-toggle-state=visible] ~ .mediaContainer {
      .transition(width, 0s);
      width: 50%;
    }

    & ~ .context-title{
      z-index: 1;
      & > div{
        top: 80px;
        bottom:inherit;
        left: 2.5rem;
      }
    }
  }
}
& .context-title{
  color: #f1f1f1;
  z-index: 10000;
  left: 2rem;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.6,0,0.4,1);
  transition: transform 0.5s cubic-bezier(0.6,0,0.4,1);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  .col(3);
  padding-left:10px;
  position: absolute;
  min-height: 100%;
  & > div{
    position:absolute;
    bottom:120px;
    margin-left: -10px;

  }
  & h1{
    max-width: 80%;
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 15px;
    line-height: 1.5rem;
    font-weight: normal;
    color: #ffffff;
    margin-top: 0.8rem;
  }
  & #info-context-overlay{
    display:inline-block;
    margin-right: 5px;
  }
}

.column{
  .col(3);

  .transition(background-color, 1s);
  .rgba(0, 0, 0, .95);
  float: right;
  right: 20px;
  vertical-align: top;
  display: block;
  height: 100%;
  padding: 0;
  margin-top: 50px;
  padding-bottom: 180px;
  height: 100%;

  &[data-toggle-state=hidden] {
    width:26px;
    position: absolute;
  }
  &[data-toggle-state=visible] {
    .transition(width, 0.5s);
    width:25%;
    position: absolute;
    padding: 0 15px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: auto;
    background-color: transparent;
    & ~ .mediaContainer{
      .transition(width, 0.5s);
      width:75%;
      float: left;
    }
  }
}

//////////////////////// GESTION TYPE DOC ////////////////////////////////

.type_document {
  width: 100%;
  height: 100%;
}

.frame-container-cell.type_document {
  vertical-align: top;
  height: 100%;

  &.no-visualisation {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .type_document {
      display: initial;
    }
  }

  .type_document {
    height: 90%;
    display: block;
    max-height: none;
    margin-top: 0;
  }
}

///////////////////////////TOOLBAR AND BUTTONS //////////////////////////////////
.viewer-controls{
  position: absolute;
  top: 0;
  height: 70px;
  display: block;
  left: 60px;
  z-index: -10;
  color: #ffffff;
  z-index: 1000000;
  & .button-bar{
    display: inline-block;
    height: inherit;
  }
  #zoomIn{
    .icon-zoom_in();
  }
  #zoomOut{
    .icon-zoom_out();
  }
  & .pdf-pagination{
    & label {
      display: inline-block;
      margin: 0 @simple-padding;
      .font(13);
      color: @font-base-color;
    }
    & input[type="number"]{
      width: 60px;
      display: inline-block;
    }
    & i {
      .font(15);
      color: @font-base-color;
      margin: 10px;
    }
    & #totalPages{
      .font(13);
      color: @font-base-color;
      margin: 10px;
    }
    & #previous{
      .icon-up_bold();
      float: right;
    }
    & #next{
      .icon-down_bold();
      float: right;
    }
  }
}






.toolbar-wrapper {
  height: @toolbar-height;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  position: absolute;
}
.bellow-img{
  bottom: 115px;
  > .toolbar{
    position: absolute;
    left: 0;
    right: 0;
  }
}
.header-toolbar{
  display: none;
}

.toolbar, .viewer-controls {
  min-height: @toolbar-height;
  line-height: @toolbar-height;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  margin-left: 15px;
  & button {
    margin: 0;
    padding: 0;
    display:inline-block;
    height: @toolbar-height;
    width: @toolbar-height;
    border: none;
    & p {display: none;}
    &:before, &:after{
      .font-size(36);
      color: @soft-border-color;
    }
  }
}

// This wrapper is used to work around a bug in firefox where an element with
// a 100% width inside an element with `table-cell` display is sized according
// to his grand-parent instead of parent.

.no-visualisation {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  margin-top: -100px;

  h3 {
    font-weight: 300;
    .font(14);
  }
}


////////////////////////    PLAYER  VIDEO    //////////////////////////////////////////



.mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: none;
  background-color: @brand-primary !important;
}

.mejs-mediaelement {
  .me-cannotplay {
    width: 100% !important;
    height: 100% !important;

    // Doesn't seem to be useful (mdarse 07/03/2014)
    display: block;
    margin: 0 auto;
  }
}

/**
 * Overwrite FlexPaper
 */
.modal-content {
  display: block !important;
}



////// SIDEBAR ///////////


.retracted-content {
  .hide();
  width: 60px;
  float: left;
  position: absolute;
  right: 0;
  z-index: 900;
}

aside {
  overflow-y: hidden;
  -ms-overflow-style: hidden;
  .content{
    float: none;
    padding: @double-padding;
    height: 100%;
    display: block;
    padding-bottom: 210px;
    .scroll-area-wrapper {
      overflow: hidden;
      position: relative;
      height: 100%;
      &::-webkit-scrollbar { width: 0 !important }
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &:hover{
        overflow-y: auto;
      }
    }
    #info-overlay{
      position: absolute;
      top: 30px;
      left: 0px;
      margin: 0;
      z-index: 999;
      &:after{
        margin-left: -10px;
      }
    }

  }
  .info {
    margin-top: 7px;
    &.retractable {
      display: none;
      position: absolute;
      top: 63px;
      right: 3px;
      cursor: pointer;
    }

    .glyphicon {
      margin-right: 15px;
      font-size: 20px;
      vertical-align: text-bottom;
    }
  }

  h4 {
    font-weight: 700;
    .font(14);
    color: @font-dark-color;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  p {
    font-family: Arial, sans-serif;
    .font(15) ;
    margin-bottom: 15px;
    margin-top: 5px;
    color: @font-ultralight-color;
  }
}



// IMAGETTES SLIDER ///////

.overlay-slider{
  position: absolute;
  display: inline-block;
  bottom: 15px;
  left: 30px;
  height: 95px;
  width: 80%;
  max-width: 80%;
  overflow: hidden;
  z-index: 10000;
  & .centered {
    max-width:inherit
  }

  & .slider-wrapper{
    display: inline-block;
    width:30000%;
    padding: 5px;
  }

  & .active{
    .opacity(1);
    outline: 3px solid @brand-primary;
  }
}

.overlay-slide{
  margin-right: 10px;
  width: 80px;
  height: 80px;
  display: inline-block;
  .opacity(0.4);
  &:hover{
    .opacity(1);
  }
  & .title{display: none;}
  & .image {

    display: block;
    position: relative;
    width: 100%;
    background-color: transparent;
    display:inline-block;
    height:100%;

    & a{
      & .thumbnail-wrapper{
        display: block;
        width: 100%;
        height:100%;
        text-align: center;
        background-color: transparent;
        &:before {
          display:inline-block;
          content: '';
          height:100%;
          width:0;
          vertical-align:middle;
        }
        & img{
          max-width: 100%;
          display: inline-block;
          max-height: 100%;
          vertical-align: middle;
        }
      }


    }
    width: inherit;
    height: inherit;
    line-height: 80px;
    float: left;

    & a {
      height: inherit;
      line-height: inherit;
      & .thumbnail-wrapper{
        display: inline-block;
        text-align: center;
        background-color: #262626;
        &  .background-thumbnail{
          display: none;
        }
      }

    }
  }

}

.overlay-slide-buttons {
  display: inline-block;
  position: absolute;
  bottom: 30px;
  right: 40px;
  .container(210);
  height: 60px;
  z-index: 1;
  & button{
    margin: 0;
    padding: 0;
    display:inline-block;
    float: left;
    width: 60px;
    height: 60px;
    &:after{
      .font(70);
    }
  }
  & #paradeprev{
    .icon-rounded_left();
  }
  & #paradenext{
    .icon-rounded_right();
  }
  & .record-counter{
    display: inline-block;
    color:#737373;
    font-size: 1.6rem;
    border-left: 1px solid #808080;
    margin-left: 1.2rem;
    padding-left: 1.2rem;
    position: relative;
    top: 9px;
    & .current-record{
      display: block;
      color: #f1f1f1;
      font-weight: bold;
      font-size: 3.2rem;
      line-height: 3rem;
      text-align: center;
    }
  }

}
/////////// MEDIA QUERIES ///////

.mq-max(1450px,
{
  .overlay-slider{ width: 75%;}
}
);
.mq-max(1200px,
{
  .overlay-slider{ width: 70%;}
}
);
.mq-max(1000px,
{
  .overlay-slider{ width: 65%;}
}
);
.mq-max(800px,
{
  .overlay-slider{ width: 60%;}
}
);
.mq-max(960px,
{
  .column[data-toggle-state=visible] {
    width:40%;
  }
  .column[data-toggle-state=visible] ~ .mediaContainer {
    width:60%;
  }
  .overlay-slide-buttons {
    right: 10px;
    width: 190px;
  }
}
);


#info-context-overlay, #info-overlay, #info-context-overlay {
  font-family: hollyglyph !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  text-decoration: none;
  font-size: 24px;
  font-size: 2.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:after {
    content: "\e93c";
  }
  &:hover, &:active{
    color: #9b8097;
    text-decoration: none;
  }
}

/////////// OVERLAY SPINNER ///////


.isloading-overlay{

  .bg-simple(@spinner)!important;

}


