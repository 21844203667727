
//// This must be remove from this styleshit

@header-height: 100px;
@header-right-height: @header-height;
@nav-height: @header-height;
@content-header-height: 450px;
@blade-element-color: @font-base-color;
////////////////////////////  ####### CONTENT HEADER - SECTIONS TITLES ########    ////////////////////////////  

.content-header {
  .container(fluid,left, @content-header-height);
  font-family: @font-family-base;
  .bg-cover(@default-header,top, center, @bg-default-color);
  text-align: center;
  background-position-y: -200px;
  @media @mobile, @tablet{
    margin-top: 0px;
    .container(fluid,left, @content-header-height - 50px);
  }
  @media @xlarge {
    background-position-y: -300px;
  }
  @media @large {
    background-position-y: -120px;
  }
  @media @desktop {
    background-position-y: -80px;
  }
  @media @tablet {
    background-position-y: -50px;
  }
  @media @mobile {
    background-position-y: 0;
  }
  .form-control {
    margin: 0 auto;
    position: relative;
    top: 10rem;
    border-radius: 7px;
    box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0px 0px 0px 7px rgba(0, 0, 0, 0.6);
  }
  .form-search {
    .mq-max(450px,{
      width: 100%;
      padding: 0 30px;
    });
  }
  .input-group-btn {
    width: 40px;
    position: relative;
    display: inline-block;
    left: 180px;
    .mq-max(450px,{
      left: 44.5%;
    });
    top: 30px;
    margin-bottom: 2rem;
    & .btn-default {
      border-radius: 0 5px 5px 0;
      padding: 0;
      &:after{
        content:'';
        background-image: url(@icon-search);
        width: 18px;
        height: 19px;
        display: inline-block;
        position: relative;
        top: 5px;
        background-size: cover;
      }
      &:focus {
        outline-width: 0;
      }
    }
  }

  
  .header-tou {
    display: inline-block;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    font-size: 18px;
    font-size: 1.8rem;
    font-family: ArialNarrow;
    @media @mobile {
      font-size: 1.5rem;
    }
    padding: 5px;
    line-height: 18px;
    color: #fff;
    a {
      display: inline-block;
      text-decoration: underline;
      color: @brand-second;
    }
    &:after{
      content:"";
      display:block;
    }
  }

  h1 {
    margin:0;
    padding:0;
    .push(1);
    display: inline-block;
    vertical-align: middle;
    .font(48);
    font-weight: 100;
    color: @textcolor-overimg;
  }
  & a {
    height: inherit;
    color: @textcolor-overimg;
  }
  h2 {
    .font(32);
    display: none;
    vertical-align: middle;
    color: @textcolor-overimg;
  }


  .media-counter {
    margin:0;
    padding:0;
    .pull(1);
    display: inline-block;
    vertical-align: middle;
    line-height: inherit;
    float: right;
    text-align: right;
    text-transform: uppercase;
    color: @textcolor-overimg;
    text-align: left;

    .number {
      .font-size(40);
      display: inline-block;
      font-weight: 400;
    }
    .unit {
      .font-size(14);
      font-weight: 700;
      display: inline-block;

    }
  }
}




////////////////////////////  ####### HEADER ########    ////////////////////////////  


header {
  .horizontal-nav();
  .header-title{
    display: inline-block;
    vertical-align: top;
    margin-left: 2rem;
    @media @mobile {
      position: absolute;
      z-index: 1;
      right: 2rem;
      top: 1rem;
      & span {
        display: block;
        text-align: right;
      }
    }
    & h2{
      text-transform: none;
      margin: 0;
      padding-top: 2.5rem;
      color: @font-ultralight-color;
      font-weight: 300;
      font-size: 24px;
      font-size: 2.4rem;
      @media @mobile {
        padding-top: 0;

      }
    }
    & span{
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: 100;
    }
  }
}


.vertical-nav{
  .col(12);
  margin-top: 40px;



  .header-bar-right {
    position: absolute;
    top: 0;
    right: @simple-padding;
    height: @header-right-height;
    @media @mobile {
      top: -2rem;
    }
    ///// CART //////

    //     .cart {

    //       // vertical-align: middle;
    //       line-height: @header-right-height;
    //       height: @header-right-height;
    //       float: right;
    //       display: inline-block;

    //         & .cart-title{
    //           color: #ffffff;
    //           .font(12);
    //           display: inline-block;
    //           vertical-align: middle;
    //           float: right;
    //           line-height: @header-right-height;
    //           margin-right: @simple-padding;
    //           height: @header-right-height;
    //           // float: right;
    //           // text-align: right;
    //         }

    //         & .glyphicon-shopping-cart{

    //           display: inline-block;
    //           vertical-align: middle;
    //           float: right;
    //           line-height: @header-right-height;
    //           height: @header-right-height;
    //           .icon-cart();
    //             &:after{
    //               line-height: @header-right-height;
    //               color: #ffffff;
    //               position: relative;
    //               top: -20px;
    //               z-index: 0;
    //           }
    //         }

    //         .cart-item-counter {
    //           position: relative;
    //           display: block;
    //           width: 18px;
    //           height: 18px;
    //           border: 1px solid #fff;
    //           background-color: #9b8097;
    //           border-radius: 50%;
    //           color: #fff;
    //           top: 3px;
    //           right: -20px;
    //           left: auto;
    //           text-align: center;
    //           padding-top: 3px;
    //           font-weight: 700;
    //           font-style: normal;
    //           z-index: 2;
    //           .font(10);


    //         }
    // /////////////HOVER CART////////////////////
    //         &:hover {

    //         }
    //     }



    ///// ACCOUNT + CONNECT //////

    .account/*.dropdown*/   {
      display: inline-block;
      float: right;
      height: @header-right-height;
      line-height: @header-right-height;
      margin-left: @double-padding;




      & a.dropdown-toggle {
        display: inline-block;
        vertical-align: middle;
        float: right;
        .font-size(12);
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
        float: right;
        line-height: @header-right-height;
        & .avatar{
          .container(40,40);
          display: inline-block;
          padding: 0;
          .icon-avatar();

          &:after{
            font-size: 40px;
            line-height: @header-right-height;
            display: inline-block;
            vertical-align: middle;
            float: right;
            color: @blade-element-color;
          }
        }
        & .caret {
          margin-left: 5px;
          display: inline-block;
          border-color: @blade-element-color;
        }
        & span{
          display: none;
        }
      }
    }
  }



  .account .dropdown-menu {

    // .icon-avatar();
    // .font(12);
  }


  ///////////////////////// SEARCH BAR ///////////////
  & .form-search {
    .row(10);
    float: right;
    padding-left: 35px;

    & .input-group{
      position: relative;
      & input {
        height: 45px;
        background-color: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: none;
        width: 100%;
        border: none;
        max-width: 100% !important;
        .font(16);
        color: #373334;
        background-color: #b3b3b3;
      }

      button, button:hover, button:active {
        width: 45px;

        .icon-search();
        &:after{
          color: #373334;
          .font-size(20);
        }
        position: absolute;
        top: 2px;
        right: 10px;
        height: 50px;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0
      }
    }
  }

}

.horizontal-nav{
  .container(100%,none,@header-height);

  @media @mobile, @tablet {
    position: relative;
  }
  @media @mobile {
    height: 150px;
  }
  color: #f1f1f1;
  background-color: @brand-second;
  z-index: 1000;
  margin: 0;
  padding: 0;
  border: 0;
  .header-bar-right {
    height: inherit;
    float: right;
    font-size: 1.5rem;
    text-align: right;
    padding-right: 6rem;
    position: absolute;
    @media @mobile {
      height: 70px;
      position: relative;
      background-color: @brand-second;
      width: 100%;
      margin-top: -1px;
    }
    .mq-max(500px,{
      padding-right: 2rem;
    }
    );
    right: 0;
    top: 0;
    & .menu-bar-right{
      position: relative;
      top: -2rem;
      right: -4rem;
      .mq-max(500px,{
        right: 0;
      }
      );
    }

    & .menu-bar-right li{
      display: inline-block;
      border-right: @border-light;
    }

    & .menu-bar-right:after {
      content: '';
      display: inline-block;
      background-image: url(@user-logo);
      width: 30px;
      height: 40px;
      position: relative;
      right: -0.3rem;
      top: 0;
    }
    & h4{
      display: inline-block;
      margin: 0;
      font-size: 2rem;
      .mq-max(500px,{
        position: relative;
        right: 4rem
      });
    }
    & span {
      font-size: 1.6rem;
      display: inline-block;
      margin-top: 3rem;
      color: #d1d1d1;
      @media @mobile {
        margin-top: 1rem;
      }
      .mq-max(500px,{
        display: none;
      }
      );
    }
    & a {
      display: inline-block;
      color: #fff;
      padding: 0 0.7rem;
      border-right: 1px solid #fff;
      font-size: 1.4rem;
      font-weight: 300;
      &:nth-last-of-type(1){
        border-right: 0;
      }
    }
    .form-search {
      width: 360px;
      margin-top: 50px;
      margin-right: 20px;
      input {
        height: 50px;
        background-color: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: none;
      }
    }

    .connect{
      display: inline-block;
      height: @header-right-height;
      line-height: @header-right-height;
      @media @mobile {
        line-height: initial;
      }
      .mq-max(450px,{
        left: 0;
      });
      position: relative;
      left: 3rem;
      & .btn{
        background-color: initial;
        margin: 0;
        &:before{
          content: "";
          display: inline-block;
          background-image: url(@icon-login);
          width: 26px;
          float: left;
          height: 30px;
          position: relative;
          right: 13px;
          top: 2px;
        }
      }
    }

  }
  //////////////////   ACCOUNT BLADE + CART ////////////////////



  .account/*.dropdown*/   {

    &.open,
    &:hover {
      color: @brand-primary;
      & a.dropdown-toggle {
        .caret {
          color: @brand-primary;
        }
      }
    }
    a.dropdown-toggle {
      display: inline-block;
      & > span {
        padding: 10px 0;
        display: inline-block;
      }
      .caret {
        margin-left: 5px;
      }
      &:hover,
      &:focus {
        avatar: transparent;
        text-decoration: none;
      }
    }
    .dropdown-menu {
      right: 0;
      left: auto;

      // Prevent header override
      > li > a {
        color: @dropdown-link-color;
        &:hover,
        &:focus {
          color: @dropdown-link-hover-color;
          color: @dropdown-link-hover-bg;
        }
      }
    }
    .picture {
      // .square(@header-height - ((@header-height - 34px)));
      padding: ((@header-height - 34px) / 2);
      vertical-align: top;
    }
  }
}

.menu-right {
  position: absolute;
  top: 0;
  right: 0;
  height: @second-nav-height;
  font-size: 0;

  /////////////////////////////////////////////////////////////////////////////////////// CART //////

  .cart {
    font-size: 1.4rem;
    line-height: @second-nav-height;
    height: @second-nav-height;
    display: inline-block;

    & .cart-link{
      line-height: @second-nav-height;
      width: 200px;
      .mq-max(500px,{
          width: 122px;
           }
      );
      & .cart-logo{
        display: inline-block;
        position: relative;
        top: 10px;
        left: -5px;
      }
    }

    & .cart-title{
      .font(16);
      font-weight: 300;
      display: inline-block;
      vertical-align: middle;
      line-height: @second-nav-height;
      margin-left: @simple-padding;
      height: @second-nav-height;
      text-align: left;
      max-width: 99px;
      overflow: hidden;
      .mq-max(500px,{
        display: none;
      }
      );
    }

    & .glyphicon-shopping-cart{
      display: inline-block;
      vertical-align: middle;
      line-height: 30px;
      height: 30px;
      font-family: hollyglyph !important;
      text-transform: none;
      .icon-cart();
      &:after{
        position: relative;
        top: -5px;
        z-index: 0;
        font-size:40px;
      }
    }

    .cart-item-counter {
      position: relative;
      font-family: @client-font-family;
      width: 31px;
      height: 29px;
      background-color: rgba(0,0,0,0.2);
      text-align: center;
      padding: 7px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 19px;
      line-height: 1.9rem;
      z-index: 10;
      display: inline-block;

    }
    /////////////HOVER CART////////////////////
    &:hover {

    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///// ACCOUNT + CONNECT //////

  .account {
    display: inline-block;
    // float: right;
    // height: @header-right-height;
    line-height: @header-right-height;
    margin-left: @double-padding;
    &:hover{
      & a.dropdown-toggle {
        & .avatar{
          &:after{
            color: @brand-primary;
          }
        }
      }
    }

    & a.dropdown-toggle {
      display: inline-block;
      vertical-align: middle;
      float: right;
      .font-size(12);
      color: @blade-element-color;
      display: inline-block;
      vertical-align: middle;
      float: right;
      line-height: @header-right-height;
      & .avatar{
        .container(40,40);
        display: inline-block;
        .icon-avatar();
        padding: 0;

        &:after{
          font-size: 40px;
          line-height: @header-right-height;
          display: inline-block;
          vertical-align: middle;
          float: right;
          color: @blade-element-color;
        }
      }
      & .caret {
        margin-left: 5px;
        display: inline-block;
        line-height: @header-right-height;
        height: @header-right-height;
      }
      & span{
        display: none;
      }
    }
    &.open,
    &:hover {
    }
  }
}



///////////////////ACCOUNT /////////////////////////



.account .dropdown-menu {
  top: @header-height + 5px;

  &:before {
    position: absolute;
    top: -10px;
    right: 19px;
    display: inline-block;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ccc;
    border-left: 10px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  &:after {
    position: absolute;
    top: -9px;
    right: 20px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #ffffff;
    border-left: 9px solid transparent;
    content: '';
  }
}



/////////////////////////////   LANG SWITCH ///////////////////////


.login, .lang-switch {
  display: inline-block;
  height: @header-height;
  line-height: @header-height;
  > li {
    display: inline-block;
    padding: 0 @simple-padding;
    text-transform: uppercase;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    &:first-child {
      border-right: 1px solid #b5b2b2;
    }

    & a:hover {
      text-decoration: none;
      color: #b5b2b2;
    }
    &.active {
    }
    &.active a {
      color: @brand-primary;
      font-weight: bold;
    }
  }
  & a {
    .font(13);
    color: @header-font-color;
    display: inline-block;
    height: inherit;
    line-height: inherit;
    vertical-align: middle;

  }
}