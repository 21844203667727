@import "/ressources/glyphs/holly-glyph.less";

/////// DECLARATION FOR A SPRITE ///////////

@icons-media: @medias-sprite-type, @medias-sprite-files,@medias-sprite-orientation, @medias-sprite-offset, @icons-media-list; 


@medias-sprite-type: 		sprite;
@medias-sprite-files: 		'../img/icons/media-sprite.svg','../img/icons/media-sprite.png';
@medias-sprite-orientation: horizontal; 
@medias-sprite-offset: 		10;
@icons-media-list: 			xls,file,txt,eps,ppt,word,flash,photoshop,illustrator,archive,audio,indesign,pdf,video,image;

@icon-home:                 '../img/logos/home.png';
@icon-login:                '../img/logos/btn-login.png';
@icon-search:                '../img/logos/search.png';
@icon-check:                '../img/logos/icon_check.png';
@icon-show:                 '../img/logos/icon_show.png';
@icon-arrow:                '../img/logos/arrow.png';
@icon-arrow-blue:                '../img/logos/arrow_blue.png';
@icon-order:                '../img/logos/icon_order.png';

/////// DECLARATION FOR A GLYPH ///////////
// .icon(@spite, xls, small);

@icons: 		@icons-type, @icons-mixin;

@icons-type:	glyph; 
@icons-mixin:	~".holly-icon()"; 



/////////// ICONS SIZES ////////////

@icon-x-small: 	12;
@icon-small: 	16;
@icon-medium: 	32;
@icon-large: 	64;
@icon-x-large: 	128;
@icon-jumbo: 	256;


@icons-default-color: @font-base-color;
@icons-default-hover-color: @font-base-color;

//////// Utilisation examples //////////


// ex: .icon(@icons-media, @txt, @small);
// ex: .icon(@icons, @burger-list, 20, #808284, #822232);
// ex: .icon(@icons, @share, 20, #808284, not);
// ex: .icon(@icons, @share);


// .icon(@file, @icon, @size:@small, @color:@icons-default-color, @color-hover:@icons-default-hover-color){

// 	// @test1:extract(@file,1) ;
// 	// @test2:extract(@file,2) ;
// 	// @test3:extract(@file,3) ;
// 	// @test4:extract(@file,4) ;
// 	// @test5:extract(@file,5) ;
// 	// @test6:extract(@file,6) ;

// 	// &:before{
// 	// 	content: "@{test1} | @{test2} | @{test3} | @{test4} | @{test5} |  @{test6}" ;
// 	// 	font-size: 12px;
// 	// }


// 	@type: extract(@file,1);
	
// 	& when (@type=sprite){
		
//   		@url: 			extract(@file,2);
// 		@orientation:	extract(@file,3);
// 		@offset: 		extract(@file,4);
// 		@list-icon: 	extract(@file,5);

// 		@url1: extract(@url,1);
// 		@url2: extract(@url,2);

// 		background: url(@url1);
// 		background: url(@url2);
// 		background-repeat: no-repeat;
    
//     	// Initiate the loop.
//     	.looper( 1 ) ;
 		
//  		@length: 		length( @list-icon);
	 	
//  		& when (@orientation=horizontal){
// 	 		// .icon-generator (@orientation,@url1, @url2, @offset,@list-icon);
// 	 		.looper( @index ) when ( @list-icon == @icon ) {


// 	 		@icon-name: extract( @list-icon, @index );

// 	 			 // @icon: {
// 	     //        	background-position: ( ( ( @index - 1 ) * @size ) *1px ) + (@offset*1px) 0px;
// 	     //        	background-color:@color;
// 	     //        	content: "@{icon}" ;
// 	            }
// 	            .looper( @index + 1 ) ;

// 			}
// 		}

// 		// & when (@orientation=vertical){
	 		
// 	 // 		.looper( @index ) when ( @index <= @length ) {

// 	 // 			@icon-name: extract( @list-icon, @index ) ;
// 	 // 			@{icon-name} : {
// 	 //            	background-position: 0px ( ( @index - 1 ) * @size ) + (@offset*1px) ;
// 	 //            	content: "@{it} is the awesome" ;
// 	 //            }
// 		// 	}
// 		// }
// 	}

// 	& when (@type=glyph){
// 		@mixin: extract(@file,2);

// 		// .glyph-generator(@mixin, @icon);
		
// 	}
// }


// // .glyph-generator (@mixin,@icon) {

// // 	&:before {
				
// // 		@mixin();
// // 		content: @icon !important;
// // 		text-decoration: none;
// // 		.font-size(@size);
// // 		color: @color;

// // 		&:hover{
// // 			color: @color-hover;
// // 		}
// // 	}

// // }

// .icon-generator (@orientation,@url1, @url2, @offset,@index ) {

// 	& when (@orientation=horizontal){
	
// 	.make-sprite( @index ) when ( @index <= @length ) {

// 		 @icon-name: extract( @list-icon, @index ) ;
// 		 @icon: {
//     	background-position: ( ( ( @index - 1 ) * @size ) *1px ) + (@offset*1px) 0px;
//     	background-color:@color;
//     	content: "@{icon}" ;
//     }
//     .looper( @index + 1 ) ;

// }
		

// }


