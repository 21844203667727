

//////////****************   GRID UTILITIES  ****************\\\\\\\\\\

@total-width: 100%;
@gridsystem-width:@total-width/@columns;

// Body rules //

// ####################### Rules ####################### //
// @bkpoint-mobile: 768px;
// @bkpoint-tablet-min:@bkpoint-mobile;
// @bkpoint-tablet-max: @bkpoint-desktop-min;
// @bkpoint-desktop-min:960px;
// @bkpoint-desktop-max:1200px;
// @bkpoint-large-min:@bkpoint-desktop-max;


body,html {
	min-width: 320px; 
	// .fixed-container();
}
/**
 * Make footer stick to bottom
 */
html {
  position: relative;
  min-height: 100%;
  height: 100%;
  background: #ffffff;
}
//margin-bottom: 90px; / Footer height + margin

.fixed-container(){
	margin: auto;
 	@media @mobile {
        max-width:@bkpoint-mobile;
    }

  	@media @desktop {
        max-width:@bkpoint-desktop-min;
    }
    
  	@media @large {
        max-width:@bkpoint-desktop-max;
    }
}

// Micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/

.clearfix() {
	*zoom:1;	
	&:after, 
	:before {
	    content:" ";
	    display:table;  
	    clear:both;   
	}
}

.container-right(@size){
	display: table;
	float: right;
	width: @size *1px;
	box-sizing: border-box;
	.clearfix();
}
.container-right(@size; @height) when(@size=fluid) and (isnumber(@height)) {
	width: 100%;
	display: table;
	float: right;
	box-sizing: border-box;
	margin: 0;
	height: inherit;
	min-height: 1px;
	.clearfix();

}

.container(@size,@float:left,@height:auto) {
	& when(@size=fluid){
	width: 100%;
	}
	& when (isnumber(@size)){
	width: @size * 1px;	
	}
	& when (isnumber(@height)){
	float: @float;
	min-height: 1px;
	height: @height * 1px;
	}
	display: block;	
	.clearfix();
}


.container-right(@size; @height) when(@size>0px) and (isnumber(@height)){
	display: table;
	float: right;
	width: @size *1px;
	box-sizing: border-box;
	height: inherit;
	min-height: 1px;
	.clearfix();

}

// .container(@size; @height) when(@size=fluid) and (@height=inherit) {
// 	width: 100%;
// 	display: table;
// 	float: none;
// 	box-sizing: border-box;
// 	margin: 0;
// 	height: inherit;
// 	min-height: 1px;
// 	.clearfix();

// }

.table-height(@height)	{
	    height: @height + 1px;
}


// .container(@size; @height) when(@size=fluid) and (isnumber(@height)) {
// 	width: 100%;
// 	display: block;
// 	float: none;
// 	box-sizing: border-box;
// 	margin: 0;
// 	height: @height*1px;
// 	min-height: 1px;
	
// }

// .container(@size; @height) when(@size>0px) and(isnumber(@height)){
// 	display: block;
// 	float: left;
// 	width: @size *1px;
// 	box-sizing: border-box;
// 	height: @height*1px;
// 	min-height: 1px;
	

// }

// .container(@size) when(@size>0px) and (default()){
// 	display: block;
// 	float: left;
// 	width: @size *1px;
// 	box-sizing: border-box;
// 	min-height: 1px;
// 	.clearfix();

// }
// .container(@size) when(@size=fluid) and (default()){
// 	display: block;
// 	float: left;
// 	width: 100%;
// 	box-sizing: border-box;
// 	min-height: 1px;
// 	.clearfix();

// }


.row(@n:@columns) {
	float: left;
	position: relative;
	width: @gridsystem-width*@n;
	padding: 0;
	min-height: 1px;
	.clearfix();
}
.row(@bkpoint;@n) {
	.clearfix();
	 @media @bkpoint {
		display: block;
		position: relative;
		float: left;
		width: @gridsystem-width*@n;
		padding: 0;
		min-height: 1px;
		
	}
}
.col(@n; @bkpoint) {
	.clearfix();
	 @media @bkpoint {
		display: block;
		position: relative;
		float: left;
		width: @gridsystem-width*@n;
		padding: 0 @gutter-width/2px;
		min-height: 1px;
		
	}
}

.col(@n:@columns){
	display: block;
	float: left;
	position: relative;
	box-sizing: border-box;
	width: @gridsystem-width*@n;
	padding: 0 @gutter-width/2px;
	min-height: 1px;
	.clearfix();
}

.push(@offset:1) {
	margin-left: @gridsystem-width*@offset;
}

.push(@bkpoint;@offset:1) {
	 
	 @media @bkpoint {
		margin-left: @gridsystem-width*@offset;
	.clearfix();
	}
}


.pull(@offset:1) {
	margin-right: @gridsystem-width*@offset;
}

.pull(@bkpoint;@offset:1) {
	
	 @media @bkpoint {
		margin-right: @gridsystem-width*@offset;
	.clearfix();
	}
}

.box-sizing (@type: @box-sizing-type) {
	-webkit-box-sizing: @type;
	-moz-box-sizing:    @type;
	box-sizing:         @type;
}
