@import "section.library.filters.less";
//////////Layout///////////////
.filters-and-products{
	.row(12);
	float: none;
	& aside.nav{
		@media @mobile {
			.row(4);
		};
		@media @tablet, @desktop {
			.row(3);
		};
	}
	& .products {
		& .form-search {
			width: 66%;
			display: inline-block;
			height: 70px;
			vertical-align: top;
			& .filter-search {
				left: 1rem;
				padding: 0;
			}
			& .input-group-btn {
				width: 40px;
				position: relative;
				display: inline-block;
				bottom: 86px;
				float: right;
			}
			& .btn-default {
				.border-radiuses(unit(@border-radius-light, px),unit(@border-radius-light, px),0,0);
				&:after {
					content: '';
					background-image: url(@icon-search);
					width: 18px;
					height: 18px;
					display: inline-block;
					position: relative;
					top: 5px;
					right: 4px;
					background-size: cover;
				}
			}
		}
		& .input-group {
			padding: 0 20px;
			& .form-control {
				position: relative;
				max-width: inherit;
				background: #fff;
				.border-radiuses(unit(@border-radius-medium, px),unit(@border-radius-medium, px),0,0);
				padding: 10px;
				margin-top: 2rem;
			}
		}
	}
}
.products{
 .row(10);
 @media @mobile {
 	.row(8);
 };
 @media @tablet, @desktop{
	.row(9);
};
 float: right;
 & .product-list{
 	display: table;
 }
	.search-right{
		width: 33%;
		display: inline-block;
		height: 80px;
		& .results{
			float:left;
			font-size: 2.2rem;
			color:@brand-second;
			text-align: left;
			padding-top: 1rem;
		}
		& .page-size-selector {
			border: 1px solid @soft-border-color;
			margin-bottom: 0;
			.border-radius(9);
			&:after {
				content: '' !important;
				background: @brand-second url(@icon-arrow) no-repeat center center;
				float: right;
				width: 39px;
				height: 39px;
				color: #fff;
				.border-radiuses(unit(9, px),0,0,unit(9, px));
				.rotate(90deg);
				position: relative;
				bottom: 3.9rem;
				cursor: pointer;
			}
			&:hover{
				&:after{
					background: darken(@brand-second, 8%) url(@icon-arrow) no-repeat center center;
				}
			}
			& select {
				position: relative;
				max-width: inherit;
				.border-radius(9);
				padding: 10px;
				border: 1px solid #f2f2f2;
				color: #6e6e6e;
				margin: 0;
				z-index: 1;
				background: transparent;
				&:focus {
					border: 1px solid @brand-primary !important;
					outline:none;
					.glow();
				}
			}
		}
	}
}



@list-controller-height: 60px;
//////////BARRE DE BOUTONS///////////////

.list-controller{
.container(fluid, left,inherit);
	margin-top: -10px;
	.clearfix();
}


.list-switch{
	display: inline-block;
	float: right;
	list-style: none;
	vertical-align: middle;
	line-height: inherit;
	height: inherit;
	& .label{
		float: left;
		display: inline-block;

		@media @mobile{
			display: none;
		};
		& p{
			.font(12);
			color: @font-base-color;
			display: inline-block;
			vertical-align: middle;
			padding: 0 @simple-padding;
			margin:0;
		}
	}  
	& li {
		display: inline-block;
		height: inherit;

		& a{
			display: inline-block;
			vertical-align: middle;
			height: inherit;
			width: @list-controller-height;
			text-align: center;
			& span {
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				height: inherit;
				font-size: 0;
				width: 100%;
				&:after{
				.font-size(26);
				display: inline-block;
				vertical-align: middle;
				line-height: inherit;
				}
			}
			
			
		}
		
	}
	& a.active {
		background-color: @brand-second;
		height: 100%;
		& span{
			&:after {
			color:#ffffff;
		}
		}
		
	}

}

.selection {
	display: inline-block;
	vertical-align: middle;
	line-height: inherit;
	padding-left: 20px;
	
	& input {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    	
    	&:before{
    	width: 20px;
    	height: 20px;
    	display: inline-block;
    	vertical-align: middle;
    	margin-left: -5px;
    	vertical-align: middle;
    	line-height: 20px;
    	}
	}
	
	& .dropdown {
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    line-height: 25px;
	    & a {
	    	line-height: inherit;
	    }
	}
}

.switch-to-product-block-list{
	& span{
		.icon-grid();	
	}
	
}

.switch-to-product-simple-list{
	& span{
		.icon-list_burger();	
	}
	
}


////////// No results ////////

#searchNoresult{
	padding-left: 30px;
	height: 500px;
	margin-bottom: 120px;
	padding-top: 200px;
	& h2{
		color: @font-base-color;
	}
}