/**
 * Overwrite alertify
 */
.alertify-logs {
  z-index: 99999;
  top: 40px;
  right: 0;
  width: 300px;

  & article {
    .font(14);
    color: #ffffff;
    .border-radius();
    .drop-shadow();
    background-color:@brand-primary;
    padding: @double-padding;
    border: 2px solid darken(@brand-primary, 10%)
  }
}

.alert-danger{
  background-color: @alert-color;
  & .alert-icon{.icon-warning();}
  & #alert-message{color: @alert-color;}
}

.alert-success{
  background-color: @success-color;
  & .alert-icon{.icon-check();}
  & #alert-message{color: @success-color;}
}

.alert-info{
  background-color: @brand-primary;
  & .alert-icon{ .icon-infos();}
  & #alert-message{color: @brand-primary;}
}


.help {
  font-size: 13px;
  color: #ababab;
}

.alert {
  margin-top: @double-padding;
  margin-bottom: @double-padding;
  padding-left: @double-padding;
  display: inline-block;
  width: 100%;
  max-width: 400px;
  min-height: 30px;
  color: @font-base-color;
  position: relative;
  .border-radius();
  .drop-shadow();

  & .alert-icon{
  display: inline-block;
  position: absolute;
  width: 30px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    display:inline-block;
    content: '';
    height:100%;
    width:0;
    vertical-align:middle;
  }
  &:after{
    display: inline-block;
    vertical-align: middle;
    color: #ffffff;
    width: 30px;
    .font(20);
    text-align: center;
    }
  }
  
  & #alert-message{
    .border-radius();
    .drop-shadow(-5, 0, 5, 0, 0.15);
    .font(12);
    background-color: #ffffff;
    height: 100%;
    display: table;
    vertical-align: middle;
    width: 100%;
    padding-top: @simple-padding; 
    padding-left:@double-padding;
    padding-bottom: @simple-padding;
    padding-right: 45px;
  }

  & button.close{
  float: right;
  display: table-cell;
  background-color: transparent;
  position: absolute;
  height: 100%;
  top:0px;
  right: 0px;
    &:after{
      .font(16);
      display: inline-block;
      vertical-align: middle;
    }
  }  
}