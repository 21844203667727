@font-face {
  font-family: 'ArialNarrow';
  src: url('../fonts/ArialNarrow.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ArialNarrow.woff') format('woff'),
  url('../fonts/ArialNarrow.ttf')  format('truetype'),
  url('../fonts/ArialNarrow.svg#ArialNarrow') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArialNarrow';
  src: url('../fonts/ArialNarrow-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ArialNarrow-Bold.woff') format('woff'),
  url('../fonts/ArialNarrow-Bold.ttf')  format('truetype'),
  url('../fonts/ArialNarrow-Bold.svg#ArialNarrow-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ArialNarrow';
  src: url('../fonts/ArialNarrow-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ArialNarrow-Italic.woff') format('woff'),
  url('../fonts/ArialNarrow-Italic.ttf')  format('truetype'),
  url('../fonts/ArialNarrow-Italic.svg#ArialNarrow-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}