/**
 * Error pages
 */
body.error {
  margin: 0;
  .bg-fixed(@body-error-bg, top, center,@bg-default-color);
  // background: @body-error-bg;
  // background-color: @soft-border-color;
  .error-container {
    .row(4);
    @media @tablet{.row(6);float: none;}
    @media @mobile{.row(10);float: none;}
    float: none;
    margin: auto;
    text-align: center;
    margin: 0 auto 0px auto;
    background-color: rgba(0,0,0,0.6);
    .border-radius(@border-radius-light);
    top: 20px;
  }
  & .logo-container {
    padding: @double-padding;
    background-color: transparent;
    .container(fluid,none,150);
    @media @mobile{.container(fluid,none,100);}
    & a{
      .bg-contain(@white-logo-png);
      height: 100%;
      width: 50%;
      display: block;

    }


  }
  .logo-container,
  .error-code,
  .description {
    @font-over-img();
    
    & h1 {
      .font-size(160);
      @media @mobile{.font-size(120);}
      @font-over-img();
      letter-spacing: -10px;
      font-weight: 100;
      margin: 0;
    }
  }
  .error-code {
    padding-bottom: 10px;
    @font-over-img();
  }
  .code {
@font-over-img();
  }
  .error-message {
    font-weight: 100;
    @font-over-img();
    text-transform: uppercase;
  }
  .description {
    padding: 30px 40px;
    h4 {
      .font(14);
      margin: 20px 0;
      @font-over-img();
    }
    & p{
      .font(14);
      @font-over-img();
    }
  }
  .btn {
    margin: 30px auto;
  

  }
  .trace {
    background-color: #ffffff;
    .border-radius(10);
    .container(90%);
    padding: @double-padding;
    float: none;
    margin:60px auto;
    .font(14);
    // border: 1px solid @soft-border-color;
  }
}


p.error {
  .font(25);
  @font-over-img();
  }
