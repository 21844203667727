@footer-height : 100px;
footer {
  .font(13);

  color: #ffffff;
  background-color: #595856;
  text-align: center;
  font-family: arial;
  vertical-align: middle;
  padding: 20px 15px;
  border-top: 1px solid #bbb;
  max-width: @body-max-width;
  margin: 0 auto;
  .block-left{
    display: inline-block;
    width: 59%;
    .mq-max(450px,{
      width: 60%;
      line-height: 1.6rem;
    });
    font-family: arial;
    vertical-align: top;
    text-align: left;
    & span {
      display: block;
    }
  }
  .block-right {
    display: inline-block;
    width: 40%;
    .mq-max(450px,{
      width: 38%;
      padding-left: 2%;
    });
    border-left: 1px solid #777777;
    padding-left: 8%;
    text-align: left;
    a {
      color: white;
      text-decoration: none;
      font-family: arial;
      font-size: 1.5rem;
    }
    h4{
      margin: 0;
      font-size:2.2rem;
      line-height: 2.6rem;
      font-family: arial;
      font-weight: normal;
      .mq-max(450px,{
        margin-bottom: 1.5rem;
      });
    }
  }
  .spacer {
    margin: 0 5px;
    display: inline-block;
    .mq-max(450px,{
      margin: 0;
    });
  }

  a{
    text-decoration: underline;
    display: inline-block;
    color: #2f96b5;

    &:hover,
    &:focus {
      color: @nav-link-hover-color;

    }
  }
}