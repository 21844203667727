
/**
 * Pagination
 */

.paginator-container{
clear: both;
.col(12);
float: right;

// border-bottom: 1px solid @border-light;
// border-top: 4px solid @brand-primary;
margin-top: @double-padding;
.clearfix();

} 
.pagination {
display: table;
.container(auto, none, 60);
margin: 0 auto;
display: inline-block;

}
.pagination a, .pagination span {
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  vertical-align: middle;
  display: table-cell;
  background-color: #dedede;
  .border-radius(5);
  padding-top: 4px;
  & .sr-only{display:none;}
}
.pagination .next, .pagination .previous {
  padding-top: 0;
}

.pagination .active a, .pagination .next, .pagination .previous {
  color: #fff;
  background-color: @brand-second;
  display: table-cell;
  &:hover {
    color: #fff !important;
    background-color: @brand-second;
  }
}

.pagination .next {
.icon-right_bold();
.font(13);

}

.pagination .previous {
.icon-left_bold();
.font(13);
}

  .pagination {
    
    display: table;
    & li {
      .border-radius(12);
      display: inline-block;
        &:hover {
          & a{
            color: #fff;
            &:after{
              color: #fff;
            }
            &:hover {
              color: #fff !important;
              background-color: @brand-second;
            }
          }
        }
  & .disabled{
     &:hover {
    background-color: transparent;
    }
  }
      list-style: none;
      vertical-align: middle;
      // outline: 1px  @border-light;
      .container(35,left,35);
      margin-right: 5px;
      display: table;
      text-align: center;
    }

  }

