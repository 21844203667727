
@transparent-gif-url: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

// Backgrounds
@body-bg-color:   #fff;
@body-default-bg: none;
@body-auth-bg:    '../img/background/auth-bg.jpg';
@body-error-bg:   @body-auth-bg;
@body-cart-bg:    @body-default-bg;
@body-catalog-bg: @body-default-bg;
@body-home-bg:    @body-default-bg;
@body-visual-bg:  @body-default-bg;
@body-basket-bg:  @body-default-bg;
@body-search-bg:  @body-default-bg;
@body-gallery-bg: @body-default-bg;
@body-account-bg: '../img/background/auth-bg.jpg';


@jumbotron-img :'../img/background/jumbotron.jpg'; 

@default-header:'../img/background/auth-bg.jpg'; 

@bg-default-color: #ffffff;