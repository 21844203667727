
body.account{
  .bg-fixed(@body-account-bg, top, center,@body-account-bg);
  & .container{
    float: none;
    margin: auto;
    & .account-container{
      margin-top: 50px;
      margin-bottom: 50px;
      .border-radius(5);
      .drop-shadow();

      @media @xlarge{
        .col(6);
        .push(3);
        float: none;
      }
      @media @large, @desktop, @tablet {
        .col(8);
        .push(2);
        float: none;
      }
      @media  @mobile{
        .col(10);
        .push(1);
        float: none;
      }
      padding: 0 @double-padding + @double-padding @double-padding;
      background-color: #ffffff;
      & .separator{
      // border-top: 1px solid @separator-color;
      }
    }
    & h1{
      .font(52);
      font-family:@font-family-base;
      color: @font-dark-color;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
      margin-bottom: 0;
    }
    & h2{
      font-family:@font-family-base;
      color: @font-base-color;
    }
    & .header-title {
      & h2 {
        color: @font-ultralight-color;
      }
    }
    & .content-header{display: none;}
    & header{
      & .form-search{display: none;}
      // & .navbar{display: none;}
    }
    & input, select {
      max-width: inherit;
      line-height: 2rem;
    }
  }
  & .alert {
    max-width: inherit;
    color: @font-ultralight-color;
    font-size: 1.3rem;
    padding: 10px;
    & ul {
      list-style: none;
    }
  }
}

