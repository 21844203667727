  ///// INDEX
  @first-visual-size: 600;
  @top-feeds: @visuals-size / 2;
  @visuals-size: 330;


body.feeds, body.feed-entry{ 
  & .content-header{
          background: none;
          height: 110px;
          line-height: 300px;
    & .feeds-header{
      & a{
      height: inherit;
      color: @font-darker-color;
      font-weight: 900;
      .font-size(120);
      position: relative;
      z-index: 900;
      }
    }
  }
}
  .feeds-container{
    .col(6);
    .push(3);
    padding-top: 50px;
    & .feeds-entry{

    }
  }


////////////////////////

  p {
    color: #808080;
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0;
    font-weight: 300;
  }
  .count {
    .row(12);
    display: inline-block;
    padding: 0 20px;
    margin: @double-padding 0;
    height: 40px;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    background: @brand-second;
    width: auto;
  }
body.feeds, body.feed-entry {
  & .download {

    .row(12);
    margin: @double-padding 0;
    & a {
      .font(12);
    }
  }
}
  #medias {
    margin-top: @double-padding;
    
    & a {
      .container(200,left,200);
      margin: @simple-padding @simple-padding 0 0;
      @media @mobile{
        .container(80,left,80);      
        margin: 0 5px 5px 0;          
      }
      @media @tablet{
        .container(120,left,120);      
        margin: 0 5px 5px 0;          
      }
      
      & div {
        padding: 0;
        width: 100%;
        height: 100%;
        float: left;
        background-size: cover;
        background-position: center center;
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
// }


.feed-container{
  & .feed-detail {  
        margin-top: -100px;
      .push(2);
      .col(6);
        @media @tablet, @mobile{
          .push(1);
          .col(10);
          .pull(1);  
        }
    & h1 {
      margin-bottom: 0;
      
      @media @xlarge, @large{
      .font(100);  
      }
      @media @desktop{
        .font(70);  
      }
      @media @tablet, @mobile{
       .font(40);   
      }
      .font(60);  
      color: @font-darker-color;
      font-weight: 900;
    }
    & .author{
      & span, time {
        display: inline-block;
      }
      .row(12);
      .font(12);
      font-weight: 400;
      color: @font-light-color;
      margin-top: @simple-padding;
      display: inline-block;
    }
    & p{
      .row(8);
      @media @tablet, @mobile{
       .row(12); 
      }
    }
    & .count{
      .container(120,left);
      display: block;
      clear: both;
    }
  }
}

/////////////// PAGINATION /////////////////

.paginate {
  float: left;
  padding: 0;
  // margin: 0 auto 30px auto;
  a {
    text-transform: capitalize;
    bottom: 0;
    right: 0;
    background: @brand-primary;
    color: #fff;
    padding: 15px 20px;
    display: block;
    float: right;
    margin-right: -8px;
    &:hover {
      text-decoration: none;
    }
  }
}


//////////// NAV MULTIPLE FEEDS ////////////////


#feeds-menu {
  margin: -15px -15px 115px -15px;
  a.first-entry {
    background: url(../img/bgd_nav_feed_first.png) right 0 no-repeat;
    color: #333a40;
    padding-right: 25px;
    padding-left: 20px;
    position: relative;
    z-index: 900;
    margin-right: -15px;
    float: left;
    display: block;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
  }
  ul {
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    list-style-type: none;
    vertical-align: middle;
    background: #f5f7f5;
    li {
      float: left;
      &.current,
      &:hover {
        a {
          background: @brand-primary;
          text-decoration: none;
          color: #fff;
        }
      }
      a {
        display: block;
        color: #808080;
        background: #f5f7f5;
        padding: 0 @double-padding;
        &.margin {
          padding-left: 30px;
        }
      }
    }
  }
  .feeds-menu-detail {
    margin-bottom: 95px;
  }
}



////////  


.feeds-entry {

  &.first {
    .row(12);
    @margin-b: (@first-visual-size / 3)*2;
    margin-bottom: ~'@{margin-b}px';

    & .visual {     
      height: ~'@{first-visual-size}px';
      width:  ~'@{first-visual-size}px';
      float: left!important;
    }
    & .content{
      .row(6);
      @media @desktop{
        .row(8);
        @top:  (@first-visual-size / 3) * 1.5;
        top: ~'@{top}px'!important;
        left: ~'@{top}px'!important;
      }
      position: absolute;
      background-color: #ffffff;
      @top:  (@first-visual-size / 3) * 2;
      top: ~'@{top}px'!important;
      left: ~'@{top}px'!important;
      padding: @double-padding;
      & h1{
        margin: 0;
        & a{
        .font(48);
        text-align: left!important;
        float: left!important;
        color: @font-darker-color;
        padding: 0 0 0 0;
        font-weight: 700;
        }
      }
      & p {float: left!important;}
      & .author {float: left!important;}
    }
  }

//////////END FIRST


  &:nth-child(2n+1){
    & .visual {     
     float: right;
    }
    & .content{
      right: ~'@{top-feeds}px';
      left: auto;
      & h1{
      margin: 0;
      float: right;
        & a{
          text-align: right;
          float: right
        }
      }
      .author {
        float: right;
        text-align: right;
      }
      p{
        float: right;
        text-align: right;
      }
    }
  }
/////////////// ALTERNANCE

  .row(12);
  @margin-b-visuals: (@visuals-size / 1.5);
  margin-bottom: ~'@{margin-b-visuals}px';
  & .visual {
    float: left;
    margin-right: 30px;
    height: ~'@{visuals-size}px';
    width: ~'@{visuals-size}px';;
    a {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-position: center center;
    }
  }
  & .content {
   .row(6);
    position: absolute;
    background-color: #ffffff;    
    top: ~'@{top-feeds}px';
    left: ~'@{top-feeds}px';
    padding: @double-padding;
    & h1{
      margin: 0;
      & a{
      .font(32);
      color: @font-darker-color;
      padding: 0 0 0 0;
      font-weight: 700;
      }
    }

    .author {
      .font(12);
      font-weight: 400;
      color: @font-light-color;
      margin: @simple-padding 0;
      & time, span {
        .font(12);
        font-weight: 400;
        display: inline-block;
        color: @font-light-color;
      }
    }
    p {
      color: #808080;
      .font(14);
      margin: 10px 0;

    }
    a.feed-link {
      position: absolute;
      bottom: -30px;
      right: 0;
      background: @brand-primary;
      color: #fff;
      padding: 15px 20px;
      .font(12);
      &:hover {
        text-decoration: none;
      }
    }
  }
}

