body.basket {
  & .container {
    background: #444341;
    min-height: 850px;
    & header{
      .row(12);
      & .navbar {
        display: none;
      }
      & .form-search{display: none;}
    }
    & .content-header {

      display: none;
    }
}
  
  
  .actions-and-products {
    
    padding-bottom: 20px;
    
    .row(12);
    float: none;
    @media @xlarge{.row(11);float: none;};
    @media @large{.row(11);float: none;};
    @media @desktop, @tablet, @mobile{.row(11);float: none;};

  
    float: none;
    clear: both;
    margin: auto;

    & .products{
     margin-top: 0; 
    }

    & .product-block-list{
     .cart-block-not-cropped();
    }

    & .product-simple-list{
      .list-cart-not-cropped();
    }
    
    & > .actions {
      
      .intro{display:none;}
      float: left; 
    }

    & > .products {
      // .make-md-column(9, 0);
      .row(12);
      margin-bottom: 50px;
      max-height: 605px;
      overflow-y: auto;
      padding: 0 @simple-padding;

      & .list-controller{display: none;}
    }
  }
}



.cart-header{
  line-height: 40px;
  .row(12);
  float:none;
  padding-left: @double-padding;
  & h1{
    float: left;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    .font(38);
    color: #848180;
    margin: 0;
    padding-right: @double-padding;
    font-weight: 300;
    max-width: 49%;
    line-height: 40px;
    margin-top: 2rem;
  }
  & .list-switch{
    display: inline-block;
    line-height: 85px;
    vertical-align: middle;
  }
}


.bar-buttons{
    display: inline-block;
    vertical-align: middle;
    float: right;
    text-align: right;
    margin-right: 0.6rem;
    width:49%;
  & form{
    display: inline-block;
    float: right;
  }

  & .btn {
    display:inline-block;
    width: initial;
    margin-right: 1rem;
    .border-radius(@border-radius-light);
    font-size: 1.4rem;
    text-align: left;
    padding-right: 0.5rem;
    font-family: ArialNarrow !important;
    margin-top: 1.5rem;
    margin-bottom: 0;
    &.rename:after {
      font-size: 3.2rem;
      padding-left: 0;
    }
    &:focus{
      color: @font-ultralight-color;
    }
    &:after{
      font-family: hollyglyph !important;
      float: right;
      font-size: 2.2rem;
      padding-left: 0.5rem;
    }
    &.action-cart-order{
      font-family: initial;
      font-weight: normal;
        &:after{
          content:'';
          background:url(@icon-order);
          width: 21px;
          height: 14px;
          display: table-cell;
          position: relative;
          top: 1.2rem;
          margin-left: 0.7rem;
          margin-right: 0.5rem;
        }
    }
  }

}


.cart-breadcrumb{
  margin-top: 30px;
  .row(12);
  float:none;
  // margin-left: @double-padding;
  height: 40px;
  padding-left: @double-padding;

  & a{
    display: inline-block;
    color: #ffffff;
    font-weight: 300;
    .font-size(24);
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;
  }
  & p{
    display: inline-block;
    color: #d5d0cf;
    font-weight: 300;
    .font-size(24);
    margin: 0;
    padding:0;
    line-height: 40px;
    float: left;

  }
  & .breadcrumb-separator{
    display: inline-block;
    color: #ffffff;
    margin: 0;
    padding:0;
    color: #afafaf;
    .icon-right_thin();
    line-height: 40px;
    float: left;
    &:after{
      display: inline-block;
    }
  }
  & .cart-counter{
    display: inline-block;
    color: #bdbdbd;
    font-weight: 700;
    .font-size(11);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: right;
    
  }
  & .unit{
    display: inline-block;
    color: #bdbdbd;
    font-weight: 700;
    .font-size(11);
    text-transform: uppercase;
    margin: 0;
    padding:0;
    line-height: 40px;
    float: right;
  }
}