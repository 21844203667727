
/**
 * Gallery
 */
body.galleries {
  & h2.galleries_title {
    color: @brand-second;
    .font(28);
    padding: @simple-padding 0;
    margin-left: 3rem;
    font-weight: 300;
    text-transform: inherit;
  }

    background-color: #fff;
    .gallery-thumbnail {
      .main-thumbnail {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 195px;
      }
    }

  .galleries_list {
    .row:first-child{border-left: 1px solid @soft-border-color;}
    .list-col {
      .col(4);
      border-top: 1px solid @soft-border-color;
      border-right: 1px solid @soft-border-color;
      border-bottom: 1px solid @soft-border-color;
    }
  }
  .item {
    a {
      text-decoration: none;
      display: block;
      width: 100%;
      height: 260px;
      overflow: hidden;
      & img {
        width: 100%;
        height: auto;
      }
      &:hover {
        h3, strong, span {
          color: @brand-primary;
        }
      }
    }
    height: 365px;
    padding-top: 15px;
    position: relative;

    h3 {
      font-size: 15px;
      line-height: 2rem;
      font-family: @font-family-base;
      color: #5E5E5E;
      font-weight: 300;
      padding: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
    }

    .gallery_counter {
      color: #4a63ae;
      float: right;
      margin-top: 20px;

      strong {
        line-height: 12px;
        text-align: right;
        display: block;
        font-size: 20px;
      }
    }

    ul.mini-squares {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;

      li {
        margin: 0;
        width: 25%;
        float: left;
        padding-top: 3px;
        padding-right: 3px;
        height: 70px;
        &.last {
          padding-right: 0;
        }
        .square-thumbnail {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}



// ############################################  JUSTIFY GALLERY OVERWRITE ############################################ //



//  ###  This part is important if you use justifiedGallery to make <figure> placement it override the justifiedGallery Css ####  //
.justified-gallery > figure > a > img {margin: 0 !important;}
.justified-gallery > figure {
position: absolute;
z-index: 0;
}

//// ##### Captions 

.justified-gallery > figure > .caption,
.justified-gallery > figure > .caption {
// background: url('../img/bgd_alpha_white.png');
background: rgba(255, 255, 255, 0.85);
-ms-filter:"progid:DXImageTransform.Microsoft.gradient(startColorstr=#85FFFFFF,endColorstr=#85FFFFFF)";
padding: 0;
height: 60px;
display: table;
vertical-align: middle;
width: 100%;
position: absolute;
bottom: 0;
padding: 15px;

margin-bottom: -1px;   //// fix an issue on the grid 

  & a {
  top: -50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: relative;
  font-size: 14px;
  height: 30px;
  display: block;
  position: relative;
  max-width: 70%;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow-multiline:ellipsis;
  color: @brand-primary;
  } 
  a:hover{text-decoration: none;}
    
  & div{
  width: 70px;
  top: -50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  position: relative;
  display: inline-block;
  height: 30px;
  float: right;
     
    button {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    display: inline-block;
    height: 30px;
    float: right;
    width: 30px;
    background: url(../img/sprite_gallery_caption.png) 0 0 no-repeat;
    margin-left: 3px;
    padding: 0;
      .glyphicon {display: none;}
      &.btn-parade-download {
          background-position: -31px 0;
      }
    }  
  }
}
