

///                                          ///
/////////////////  DROPDOWNS ///////////////////
///                                          ///



// *******  OLD BOOTSRAP STYLE


.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top:   @caret-width-base solid;
  border-right: @caret-width-base solid transparent;
  border-left:  @caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropdown-toggle{margin: 0 5px;display:inline-block;vertical-align: middle;position: relative;}
.dropdown {
  position: relative;
  display: block;
  height: 40px;
  min-width: 40px;
  & > a {
    .font(13);
    color: @brand-second;
  }
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;

}

// The dropdown menu (ul)
.large-dropdown{
width: 320px;
right: -20px;
left: auto!important;
padding: @simple-padding;
}
.dropdown-menu {
  
  top: 100%;
  left: 0;
  z-index: 99999999;
  display: none; 
  float: left;
  min-width: 160px;
  margin: 2px 0 0; 
  list-style: none;
  background-color: #ffffff;
  border: 1px solid @soft-border-color;
  .border-radius();
  .drop-shadow();
  position: absolute;
  // // Dividers (basically an hr) within the dropdown
  // .divider {
  //   .nav-divider(@dropdown-divider-bg);
  // }

  // Links within the dropdown menu
  > li > a {
    display: block;
    clear: both;
    .font(13);
    position: relative;
    z-index: 100000000000;
    color: @font-base-color;
    padding: @simple-padding;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: @dropdown-text-hover;
    background-color: @brand-primary;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: @dropdown-text-hover;
    text-decoration: none;
    outline: 0;
    background-color: @dropdown-link-active-bg;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: @dropdown-link-disabled-color;
  }
}
// Nuke hover/focus effects
.dropdown-menu > .disabled > a {
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    // .reset-filter();
    cursor: not-allowed;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: @font-size-small;
  line-height: @line-height-base;
  color: @dropdown-header-color;
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: (@zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    float: right;
    display: inline-block;
    vertical-align: middle;
    border-top: 0;
    border-bottom: @caret-width-base solid;
    content: "";
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
  }
}


///////////////////   ACOUNT ////////////////////////


.dropdown-account {
  font-family: @font-family-base;
  & .avatar{
    .icon-avatar();
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    float: left;
    
    &:after{
      .font(55);
      vertical-align: middle;
      display: inline-block;
      color: @font-dark-color;
    }
  }
  & li{
    float: left;
    padding-right: @simple-padding;

    & a {
      padding: @simple-padding 0;
      color: @font-light-color;  
      .font(13);
      

      &:hover{
        background-color: transparent;
        color:@font-dark-color;  ;
      }
    }   
  }
  & li.credentials{
    float: left;
    padding-left: @simple-padding;
    display: block;
    width: 200px;
     
    & h4{
      font-family: @font-family-base;
      float: left;
      .font(24);
      font-weight: 300;
      margin: 0;
      color: @font-base-color;
      word-wrap: break-word;
      margin: 5px 0;

    }
     
    & p{
      .font(13);
      font-weight: 300;
    }
  }
}


