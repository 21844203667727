/**
 * Small modal
 */

// .paradeModalBackground{
// // width: 100%;
// // height: 100%;
// background-color: #ffffff;
// .opacity(0.9);
// position: fixed;
// top: 0;
// display:none;

// } 
.modal-body{
  & .container{
    .font(15);
  }
  & h2{
    color: @font-ultralight-color;
    font-weight: 300;
  }
  & h3{

  }
  & input, textarea{
    background-color: #ffffff;
    border-color: #DDDDDD;
    margin: 0 auto;
    &:hover, :focus{
      background-color: #ffffff;
    }
  }

}

#cart-order-form {
  text-align: center;
}

.modal-dialog{
  background-color: #ffffff;
  & .modal-header {
    padding: @simple-padding;
    padding-bottom: 0;
    background: grey;
  }
  & .modal-download {
    padding: @double-padding;
    padding-top: 0;
  }
}
  .modal{
    &:before {
          display:inline-block;
          content: '';
          width:0;
          vertical-align:middle;
        }
    & .large{
      
      // min-height: 500px;
      float:none;
      display: inline-block;
      // margin: 80px auto;
      // padding: @double-padding;
      // .drop-shadow();
      // .border-radius();

    }

  }
  .modal-dialog{
    // overflow: hidden;
    & #paraclose{
      top: @simple-padding;
      right: @simple-padding;
      color: @font-ultralight-color;
    }
    width: 99%;
    float: none;
    vertical-align: middle;
    display: inline-block;
    .drop-shadow();
    .border-radius();
  }
.paradeModalSmall{
  .container(450);
   float: none;
   margin: auto;
}

 .paradeModalLarge{
  .container(800);
   float: none;
   top: 5%;
   bottom: 5%;
   // margin: auto;
   // & .modal-dialog{
   //  .container(800);
   //  display: inline-block;
   // }
    .modal-content{
      display: inline-block;
      // overflow: hidden;
  }

   & #paraclose{
     top: @simple-padding;
     right: @simple-padding;
     color: @font-base-color;
   }
}

  .modal-body {
    position: relative;
    font-family: ArialNarrow;
    & .help {
      padding-left: 25px;
    }
    & #form-recipients {
      & .delete, .add {
        font-size: 1.3rem;
        margin: 0.2rem 0;
        display: inline-block;
      }
    }
  }

.cc-window {
  z-index: 1000;
}

.modal-open {
  overflow: hidden;
}

.modal {
  margin: auto;
  display: none;
  overflow: auto;
  // overflow-y: scroll;
  position: fixed;
  top: 10%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;


  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    // .translate(0, -25%);
    // .transition-transform(~"0.3s ease-out");
  }
  &.in .modal-dialog { .translate(0, 0)}
}
.paradeModalBackground{
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal - 1;
  -webkit-overflow-scrolling: touch;

}
#cart-dialog-form {
  & button[type="submit"] {
    margin-bottom: 0;
  }
}

p.instructions{
font-style: italic;
color: @font-light-color;
.font(11);
}

.modal-header {
  & h2{
    margin: 5px 0 10px 0;
    text-transform: none;
  }

}