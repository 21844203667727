body.contact{
  & .container{
    background: #444341;
    float: none;
    margin: auto;
    & .contact.last-items {
      background: #444341;
    }
    & .contact-container{
      margin-top: 50px;
      margin-bottom: 50px;
      .border-radius(5);
      .drop-shadow();
      .col(12);
      position: relative;
      box-shadow: none;
      float: none;
      & h1 {
        color: @font-ultralight-color;
        .font(36);
        margin-top: 0;
        padding-left: @simple-padding;
      }
      & .form-container {
        .col(8);
        @media @tablet, @mobile {
          .col(12);
        }
        & .info-required {
          position: relative;
          bottom: 8rem;
          & p {
            color: #cccccc;
            text-align: right;
          }
        }
      }
      & .contact-info {
        .col(4);
        @media @tablet, @mobile {
          .col(12);
        }
        float: right;
        color: @font-ultralight-color;
        & .infos {
          border: 1px solid #848180;
          padding: @double-padding;
          margin-top: 3.5rem;
          @media @tablet, @mobile {
            padding: @simple-padding;
          }
          padding-top: 0;
          & h3 {
            @media @tablet, @mobile {
              margin: 0;
            }
            text-transform: none;
            font-size: 18px;
            font-size: 1.8rem;
            line-height: 28px;
            line-height: 2.8rem;
          }
          & ul {
            list-style: none;
            & li {
              line-height: 22px;
              font-size: 16px;
              font-size: 1.6rem;
              padding-top: 2rem;
              padding-bottom: 2rem;
              @media @tablet, @mobile {
                padding-top: 1rem;
                padding-bottom: 1rem;
              }
              &:before {
                content: '';
                width:12px;
                height:12px;
                float: left;
                background: @brand-second;
                margin-right: 1rem;
                position: relative;
                top: 0.4rem;
              }
            }
          }
        }
      }

      padding: 0 @double-padding + @double-padding @double-padding;

      & .separator{
        // border-top: 1px solid @separator-color;
      }
    }
    & h1{
      .font(32);
      font-family:@font-family-base;
      color: @font-light-color;
      font-weight: 700;
      display: inline-block;
    }
    & h2{
      font-family:@font-family-base;
      color: @font-base-color;
    }
    & .header-title {
      & h2 {
        color: @font-ultralight-color;
      }
    }
    & .content-header{display: none;}
    & header{
      & .form-search{display: none;}
      // & .navbar{display: none;}
    }
    & input, select, textarea {
      max-width: 100%;
      line-height: 2rem;
    }
    & label {
      float: none;
      color: #cccccc;
      line-height: 3rem;
      &.required {
        &:after {
          content: '*';
          margin-left: 0.2rem;
        }
      }
    }
    & button.close:after {
      content: "";
    }
    button[type="submit"] {
      max-width: 200px;
      background-color: @brand-second !important;
      border: 0 !important;
      text-align: left;
      margin: 15px 0;
      &:after {
        content: '' !important;
        background: url(@icon-arrow) no-repeat center center;
        float: right;
        width: 25px;
        height: 25px;
        color: #fff;
        position: relative;
        top: 0.6rem;
        left: 1rem;
      }
    }
  }
  & .alert {
    max-width: inherit;
    color: @font-ultralight-color;
    font-size: 1.3rem;
    padding: 10px;
    margin: 10px 0;
    & ul {
      list-style: none;
    }
  }
}