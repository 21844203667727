@nav-height: 100px;
@second-nav-height: 70px;

.main-nav{}

.logo-container{
  .container(200);
  height: @nav-height;
  line-height: @nav-height;
  text-align: center;
  display: inline-block;    
  @media @mobile{
    width: 50%;
    height: 80px;
  }

  & a {     
    display: block;
    line-height: inherit;
    vertical-align: center;
    margin: auto;
    width: 70%;
    max-width: 150px;
    height: 100%;
    .bg-contain(@white-logo-png,center,center);
  }
}

.navbar{
  display: inline-block;
  line-height: inherit;
  margin: 0;
  padding: 0;
  font-family:@font-family-base;
  color: @font-base-color;
  .font-size(13);
  vertical-align: top;
  background-color: #ffffff;
  width: 100%;
  position: relative;
  border-bottom: @input-border-hover;
    @media @mobile, @tablet{
      .row();
      height: auto;
      float: none;
    };
  & ul{
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    height: @second-nav-height;
    line-height: @second-nav-height;
    vertical-align: middle;
    
    @media @mobile, @tablet{
      .row();
      width: initial;
    };

    & .active{
      & a {
      color: @brand-primary!important;
      }
    }

  & li {
    background-color: #ffffff;
    display: inline-block;
    color: @font-base-color;
    margin: 0;
    text-align: center;
    vertical-align: top;
    padding:0;
    font-weight: 300;
    height: inherit;
    line-height: inherit;
    margin-bottom: 1px;

    &.first{
      & a{
        &:before{
          content: normal;
        }
      }
    }

    &:hover{
      cursor: pointer;
      color: @brand-secondary;
    }

    &:first-child{
      padding-left: @double-padding;
      border-right: @input-border-hover;
      &:before{
        content: "";
        display: inline-block;
        background-image: url(@icon-home);
        width: 29px;
        float: left;
        height: 29px;
        position: relative;
        right: 7px;
        top: 16px;
      }

    }

    > a {
      position: relative;
      display: block;
      padding: 0;
      color: inherit;
      .font-size(16);
      font-weight: 300;
      line-height: inherit;
      padding: 0 @double-padding;
     
      &:hover,
      &:focus {
        text-decoration: none;
        color: @nav-link-hover-color;
      } 
    }
  }
}


  .filters-wrapper.collapse {
    display: block;
  }

  .navbar-collapse .navbar-nav.navbar-right {
    margin-right: -15px;
  }

  .last-items {
    display: inline-block;
    width: 160px;
    line-height: 70px;
    height: 70px;
    background: #595856;
    border-left: 1px solid #aaa;
    font-size: 1.6rem;
    font-weight: 300;
    color: #fff;
    text-align: center;
    vertical-align: top;
    .mq-max(500px,{
      width: 68px;
      font-size: 0;
    }
    );
    & .contact-logo{
      position: relative;
      left: -15px;
      top: 6px;
      .mq-max(500px,{
        left: 0;
        top: 1rem;
      }
      );

    }
    a, span {
      color:#fff;
    }
    a{
      font-weight:bold;
      font-size: 1.4rem;
    }
  }
}
.basket {
  .last-items {
    &.cart-link {
      background: #444341;
    }
  }
}