
//////////////////////// CLASSIC BREADCRUMBS ///////////////////////////


.breadcrumb{
	list-style: none;
	.container(fluid, left, 40);
	& li{
		height: inherit;
		display: block;
		& a {
		height: inherit;
		color: @textcolor-overimg;
		}
	}
	
}


//////////////////////// FILTERS BREADCRUMB ///////////////////////////

.filters-breadcrumb{
	.row(12);
	padding: 0 0.5rem;
	& .filter-item{
	display: inline-block;
	position: relative;
	margin-bottom: 5px;
	.border-radius();
	background-color: @brand-second;
	line-height: 35px;
	padding-left: @simple-padding;
	margin-right: 3px;

		& p {
			display: inline-block;
			color: #ffffff;
			vertical-align: middle;
			margin:0;
		}
		& .filter-label{
			.font-size(12);
			font-weight: 300;
		}
		& .filter-value{
			margin-left: 5px;
			.font-size(14);
			font-weight: 700;
			text-transform: uppercase;
		}
		& #paraclose{
			display: inline-block;
			position: relative;
			float: none;
			vertical-align: middle;
			margin:0;
			height: 35px;
			font-size: 0;
			right: 0;
			top: 0;
			&:after{
			.font-size(12);
			color: #b3b3b3;
			display: inline-block;
    		vertical-align: middle;
			}
			&:hover{
				&:after{
				color: #ffffff;
			}
			}
		}
	}
}

