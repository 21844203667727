aside.nav{
.col(2);
 & form {
   & .header{display: none;}
   & .reset{display: none;}
  }
  padding: 0;
  border-right: @input-border-hover;
}

#filters{ 
  height: 100%;

  hr{display: none;}
}

#filters{
  & >.form-group{
       & label.required {
         .font(16);
         font-weight: normal;
         color: @font-base-color;
         margin: 0;
         padding: 15px;
         background: #F5f5f5;
         border-bottom: @input-border-hover;
         width: 100%;
         display: block;
         &:hover  {
           cursor: pointer;
         }
         &.selected {
           background: #fff;
           &:before {
             content: '' !important;
             background: @brand-second url(@icon-arrow) no-repeat center center;
             float: left;
             width: 25px;
             height: 25px;
             color: #fff;
             .border-radius(@border-radius-light);
             margin-right: 1rem;
             .rotate(90deg);
           }
         }
         &:before {
           content: '' !important;
           background: #888 url(@icon-arrow) no-repeat center center;
           float: left;
           width: 25px;
           height: 25px;
           color: #fff;
           .border-radius(@border-radius-light);
           margin-right: 1rem;
         }
       }
  }
}

/////////////// SEARCH TYPE ////////////////

.search_type{
  & input[type="radio"] + label{
    .font(12)!important;
    &:hover{
      color: @brand-primary !important;
      cursor: pointer;

    }
  }

  & input[type="radio"]:checked + label {
    color: @brand-primary !important;
    .font(12)!important;
  }
}

//////////////// FACETS  ///////////////////
.facets-container{
        overflow: hidden;
          border-collapse: collapse;
    &::-webkit-scrollbar { width: 0 !important }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none
}

.facets {
  overflow: hidden;
  max-height: 450px;
  float: left;
  & h4{
    display: none;
  }
  & ul {
    width: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-collapse: collapse;
    display:none;

    &:hover{
      overflow-y: auto;
    }
  }
  & ul > li {
    min-height: 25px;
    list-style: none;
    padding: 5px 15px;
    border-bottom: @border-light;
    & input[type="checkbox"]{
      display: none;
    }
    & label {
      .font(13);
      font-weight: 300;
      color: @font-base-color;
      text-transform: none;
      float: none;
      display: block;
      font-family: ArialNarrow;
      &:before {
        content: "" !important;
        background: url(@icon-arrow-blue);
        float: left;
        margin-right: 1rem;
        width: 8px;
        height: 14px;
        position: relative;
        top: 3px;
      }
      &:hover{
        color: @brand-primary;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}


//////////////// FILTERS ///////////////////

.form-group{
  & .checkbox{
    & input[type='radio']{
      display: none;
    }
    & input[type='checkbox']{
      display: none;
    }
    & label{
      .font(13);
      font-weight: 300;
      color: @font-base-color;
      text-transform: none;
    }
    & label[for="filters_search_type_0"]{
      color: @brand-primary;
    }
  }
}
