
.block-not-cropped{
  padding: @simple-padding @double-padding @double-padding @simple-padding;
  & li {
    position: relative;
    list-style: none;
    z-index: 0;
    @item-gutter: 15px;
    padding-left: @item-gutter;
    padding-bottom: @item-gutter;
    .items(@items-default);
    @media @xlarge{.items(@items-xlarge);}
    @media @large{.items(@items-large);}
    @media @desktop{.items(@items-desktop);}
    @media @tablet{.items(@items-tablet);}
    @media @mobile{.items(@items-mobile);}
    .border-radius();

    & .item-paginate {
      margin-top: 1px;
      margin-left: -1px;
      height: @item-height + 5px;
      display: table;
      padding: @double-padding;
      background-color: transparent !important;
      outline: 1px solid @blocks-paginate-outline;

      & a {
        display: block;
        position: relative;
        height: 100%;
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        .font(22);
        color: @brand-second;

        &:hover{
          text-decoration: none;
        }
      }
    }

    & .item{
      outline: 1px solid @blocks-outline;
      box-sizing: border-box;
      width: 100%;
      background-color: @item-bg-color;
      border: 1px solid #bbb;
      background-color: #ffffff;
      box-sizing: border-box;
      height: @item-height;
      overflow: hidden;
      & .image {
        display: table;
        position: relative;
        width: 100%;
        background-color: @thumbnail-background-color;
        @media @desktop, @tablet, @mobile {height: @img-height; line-height: @img-height; max-height: @img-height;};
        @media @mobile {height: @img-height; line-height: @img-height; max-height: @img-height;};

        max-height: @img-height;
        height:@img-height;
        line-height: @img-height;
        overflow: hidden;
        & a{
          display: table-cell;
          position: relative;
          cursor: pointer;
          width: inherit;
          text-align: center;
          vertical-align: middle;
          height: inherit;
          line-height: inherit;
          max-height: inherit;
          .border-radius();
          & .icon-only{
            width: 100%;
            height: 100%;
            background-color: @soft-border-color;
            line-height: inherit;
            padding: 0;
            & span{
              .file-icon-large();
              display: inline-block;
              vertical-align: middle;
              & img {
                @media @tablet, @desktop, @large, @xlarge {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  height: 100%;
                  width: auto;
                  -webkit-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }

      & .caption{
        height:@cartouche-height;
        padding: @simple-padding;
        display: table;
        width: 100%;
        padding-bottom: 0;
        font-family: ArialNarrow;

        .media-title{
          display: block;
          vertical-align: top;
          width: 100%;
          max-height: 40px;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;

          & a{
            .font(14);
            font-weight: 300;
            color: @brand-primary;
            text-align: center;
          }
        }
        .media-date{
          display: inline-block;
        }
        & .extra-fields{
          width: 100%;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          & p{
            .font(11);
            margin: 0;
            color: #A2A2A2;
          }
        }
      }

      & .actions {
        display: inline-block;
        float: right;
        top: -3.5rem;
        position: relative;
        right: 1rem;
        & a{
          width: 100%;
          height: 100%;
          display: inline-block;
        }
        & .bnt-wrapper{
          vertical-align: middle;
          display: inline-block;
          position: relative;
          bottom: 0.5rem;
          & button {
            width: 28px;
            padding: 0;
            color: @brand-second;
            cursor: pointer;
            height: 25px;
            &:hover {
              color: darken(@brand-second, 10%);
            }
          }
          & button.show {
            display: inline-block;
          }
          & .item-btn {
            @media @large, @xlarge{ width: 200px;};
            width: 160px;
            margin-top: 5px;
            display: block;
            vertical-align: middle;
            &:first-child{margin-top: 0px;}
            &:after{display: none;}
          }
        }
      }

      & .selector-container{
        visibility: hidden;
        position: absolute;
        display:block;
        z-index: 11;
        padding: @simple-padding;
      }
    }
  }
}

// ############### ANIMATIONS & BEHAVIOURS ###############  //
.block-not-cropped{
  li{
    &:hover, &.selected{
      z-index: 3;

      & .item-paginate{
        outline: 1px solid @brand-primary;
        & a{
          color: @brand-primary;
        }
      }

      & .item{
        border: 1px solid @brand-second;
        & a {
          color: @brand-second;
          cursor: pointer;
        }
        & .image{
          & .thumbnail-wrapper{
            //outline: 3px solid @brand-second;
            background-color: #c2dae1;
          }


        }

        & .caption{
          & .media-title{
            & a {
              color: @brand-second;
            }
          }
        }

        & .actions{
          display: inline-block;
        }
      }

      .selector-container{
        visibility: visible;
        z-index: 20;

        & input {
          display: inline-block;
          line-height: 10px;
          .icon-checkbox_off();
          z-index: 999;

          &:before{
            color:#ffffff;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
        }
      }

      .bnt-wrapper{
        display: block;
      }
    }

    ///END HOVER


    .selected {
      z-index: 3;
      border-bottom: 5px solid @brand-second;

      a {
        color: @brand-primary;
        cursor: pointer;
      }
      & .image{
        & .thumbnail-wrapper{
          outline: 3px solid @brand-second;
          background-color: #c2dae1;
        }
      }

      & .caption{
        & .media-title{
          & a {
            color: @brand-primary;
          }
        }
      }
      & .selector-container{
        visibility: visible;
        z-index: 20;

        & input[type="checkbox"]~label:before{
          content: @holly-check;
        }
      }
    }
  }
}